import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function ContactForm({ buttonText }) {
	const [state, handleSubmit] = useForm("myyqrele");
	const [gclid, setGclid] = useState(""); // New state to store gclid

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const gclidParam = urlParams.get("gclid");
		if (gclidParam) {
			setGclid(gclidParam); // Store the gclid in state if it exists
		}
	}, []);

	const handleFormSubmit = (event) => {
		event.preventDefault(); // Prevent default form submission

		const formData = new FormData(event.target);
		const customerEmail = formData.get("email");
		const customerPhone = formData.get("phone");
		const customerName = formData.get("name");

		// Push to dataLayer after form submit
		if (typeof window !== "undefined" && window.dataLayer) {
			window.dataLayer.push({
				event: "generate_lead",
				form_name: "ContactForm", // You can change this based on the form name
				customer_email: customerEmail,
				customer_phone_number: customerPhone,
			});
		}

		handleSubmit(event); // Proceed with Formspree submission
	};

	if (state.succeeded) {
		window.location.href = "/thank-you";
		return null;
	}

	return (
		<Form className="w-100" onSubmit={handleFormSubmit}>
			<Form.Group className="mb-3" controlId="name">
				<Form.Control
					className="py-3 pb-xl-5 fs-5"
					name="name"
					placeholder="Name"
					type="text"
					required
				/>
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />

			<Form.Group className="mb-3" controlId="phone">
				<Form.Control
					className="py-3 pb-xl-5 fs-5"
					name="phone"
					placeholder="Phone"
					type="tel"
					required
				/>
			</Form.Group>
			<ValidationError prefix="Phone" field="phone" errors={state.errors} />

			<Form.Group className="mb-3" controlId="email">
				<Form.Control
					className="py-3 pb-xl-5 fs-5"
					name="email"
					type="email"
					placeholder="Email"
					required
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />

			<Form.Group className="mb-4" controlId="message">
				<Form.Control
					placeholder="Message"
					className="py-3 pb-xl-5 fs-5"
					name="message"
					as="textarea"
					rows={5}
				/>
			</Form.Group>
			<ValidationError prefix="Message" field="message" errors={state.errors} />

			{/* Hidden field for gclid */}
			<Form.Group controlId="gclid" className="d-none">
				<Form.Control type="hidden" name="gclid" value={gclid} />
			</Form.Group>

			<div className="text-center">
				<Button
					variant="primary"
					className="px-5 mt-4 text-uppercase text-white me-md-3 py-3 w-100"
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					{buttonText ?? "Submit"}
				</Button>
			</div>
		</Form>
	);
}

export default ContactForm;
