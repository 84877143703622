import React, { useRef } from "react";
import Col from "react-bootstrap/Col";
import { Container, Row } from "react-bootstrap";
import useOnScreen from "./use-on-screen";
import CountUp from "react-countup";
import ScorecardModal from "../components/scorecardModal";
import { SafeHtmlParser } from "./safe-html-parser";

const BumperBanner = ({
	image,
	heading,
	subHeading,
	content,
	items,
	button,
	hasGreenBg,
}) => {
	const ref1 = useRef();
	const onScreen1 = useOnScreen(ref1, "0px");

	return (
		<section
			className={`py-4 py-md-5 py-lg-7 ${
				hasGreenBg ? "bg-primary" : "bg-white"
			}`}
		>
			<Container ref={ref1}>
				<Row className="g-5 gx-lg-7">
					<Col lg={5}>
						{subHeading && (
							<p className="text-uppercase  text-secondary pb-2 mb-0 ssp-semibold">
								{subHeading}
							</p>
						)}

						<h2 className=" text-primary mb-3 ">{heading}</h2>
						{content && <SafeHtmlParser htmlContent={content} />}
						<ScorecardModal image={image} buttonText={button.title} />
					</Col>
					{items && items.length > 0 && (
						<Col lg={7}>
							<Row className="g-0">
								{items.map((item, i) => (
									<Col
										className="text-center"
										style={{
											borderRight: (i + 1) % 2 !== 0 ? "1px solid #014C46" : "",
										}}
										xs={6}
										key={i}
									>
										<p className="display-5 pb-0 mb-0 text-dark-green merriweather">
											{item.heading !== "Opened" && onScreen1 && (
												<CountUp end={item.count} />
											)}
											{item.heading === "Opened" && onScreen1 && (
												<div>{item.count}</div>
											)}
										</p>

										<p className="fs-2 text-dark-green pb-0 mb-0">
											{item.heading}
										</p>
										{i < 2 && (
											<div
												className="my-4 w-100 bg-primary"
												style={{ height: "1px" }}
											></div>
										)}
									</Col>
								))}
							</Row>
						</Col>
					)}
				</Row>
			</Container>

			{/* <Container ref={ref1} className="">
        <Row className="">
          <Col className="text-center pb-5">
            <h2>Split the cost of your repairs with Bumper</h2>
          </Col>
        </Row>
        <Row>
          <Col className="text-center" lg={3}>
            <h3 className="text-primary display-2">
              {onScreen1 && <CountUp start={10} end={0} />}%
            </h3>
            <p className="fs-3">
              Interest-free
              <br /> payments
            </p>
          </Col>
          <Col className="text-center" lg={3}>
            <h3 className="text-primary display-2">
              £{onScreen1 && <CountUp end={5} />}k
            </h3>
            <p className="fs-3">
              Up to £5,000
              <br /> credit limit
            </p>
          </Col>
          <Col className="text-center" lg={3}>
            <h3 className="text-primary display-2">
              1-{onScreen1 && <CountUp end={6} />}
            </h3>
            <p className="fs-3">
              Split payments
              <br /> over 1 - 6 months
            </p>
          </Col>
          <Col className="text-center" lg={3}>
            <h3 className="text-primary display-2">
              {onScreen1 && <CountUp start={5} end={2} />}
            </h3>
            <p className="fs-3">
              2-click application.
              <br /> Quick and fast
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center pt-5">
            <a
              className="fs-4 inter-bold black-link "
              target="_blank"
              rel="noreferrer"
              href="https://www.bumper.co.uk/"
            >
              Apply now with Bumper
            </a>
          </Col>
        </Row>
      </Container> */}
		</section>
	);
};

export default BumperBanner;
