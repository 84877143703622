import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { SafeHtmlParser } from "./safe-html-parser";

function RulesModal({ content, buttonText }) {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<Button
				onClick={handleShow}
				style={{ borderRadius: "0px" }}
				variant="white"
				className="py-3 w-100 text-primary w-md-auto px-6 me-md-4 mt-3 my-lg-0 my-md-0"
			>
				{buttonText ?? "Our rules"}
			</Button>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header
					closeButton
					className="bg-primary text-white"
					closeVariant="white"
				>
					<Modal.Title>Our rules</Modal.Title>
				</Modal.Header>
				<Modal.Body className="bg-primary">
					<h2 className="line-heading text-white mb-4 fs-1">Course rules</h2>
					{content && <SafeHtmlParser htmlContent={content} />}
				</Modal.Body>
				<Modal.Footer className="bg-primary">
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default RulesModal;
