import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { Link } from "gatsby";
import Modal from "react-bootstrap/Modal";
import { SafeHtmlParser } from "../components/safe-html-parser";

const HomeHero = ({ title, subHeading, content, button, button2, video }) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <section
      style={{ overflow: "hidden", maxHeight: "88vh" }}
      className="position-relative bg-primary"
    >
      <video
        className="video"
        loop
        autoPlay
        muted
        src={video}
        playsinline
        type="video/mp4"
      >
        Sorry, your browser doesn't support HTML5 video tag.
      </video>
      <div
        style={{ zIndex: 1, opacity: 0 }}
        className="bg-black w-100 h-100 position-absolute start-0 top-0"
      ></div>
      <Container
        style={{ zIndex: 2 }}
        className="position-absolute  start-50 translate-middle top-50"
      >
        <Row className="  pt-3 ps-lg-5">
          <Col className="pl-0 ">
            <p className="text-uppercase text-secondary pb-1 mb-0 ssp-semibold d-none d-lg-block d-md-block">
              {subHeading ?? "Welcome to Toot Hill"}
            </p>
            <h1
              className="pt-3 mb-3 text-white text-center text-md-start display-4"
              id="services-hero-title"
            >
              {title ?? "No ordinary course"}
            </h1>
            {/* <p className="text-white fs-5 d-none d-lg-block d-md-block">
              Located just off the M11 and M25 this undulating parkland
              <br className="d-none d-lg-block" /> course is undoubtedly one of
              the finest in Essex.{" "}
            </p> */}
            {content && (
              <div className="text-white fs-5 d-none d-lg-block d-md-block">
                <SafeHtmlParser htmlContent={content} />
              </div>
            )}
          </Col>
        </Row>
        <Row className="ps-lg-5 pb-5">
          <Col>
            {button && button.url && (
              <Button
                style={{ borderRadius: "0px" }}
                variant="primary"
                href={button.url}
                target={button.target ?? "_blank"}
                rel="noreferrer"
                className="py-3 w-100 text-white w-md-auto mb-4 mb-md-0 px-5 me-md-4"
              >
                {button?.title}
              </Button>
            )}

            {button2 && button2.url && (
              <Button
                style={{ borderRadius: "0px" }}
                variant="outline-white"
                as={Link}
                to={button2.url}
                className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 px-5 d-none d-lg-inline d-md-inline"
                target={button2.target ?? "_blank"}
              >
                {button2.title}
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeHero;
