import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
	createRef,
} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import lottie from "lottie-web";
import parse from "html-react-parser";
import { Card, Carousel } from "react-bootstrap";
import HomeHero from "../components/home-hero";
import StarLogo from "../images/icons/star.svg";
import FacebookLogo from "../images/bi_facebook.svg";
import XLogo from "../images/logo 1.svg";
import InstaLogo from "../images/Frame 62.svg";
import ContactForm from "../components/contactForm";
import BumperBanner from "../components/numbersSection";
import ImageCarousel from "../components/changingImage";
import RulesModal from "../components/rulesModal";
import Reviews from "../components/reviews";
import MenuModal from "../components/menuModal";
import { checkPropertiesForNull, groupArrayInPairs } from "../utils/object";
import { SafeHtmlParser } from "../components/safe-html-parser";
import uuid4 from "uuid4";

const IndexPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			reviews {
				reviewCount
				reviews {
					author
					datePublished
					source
					reviewBody
				}
			}
			pageData: wpPage(slug: { eq: "home" }) {
				seoFieldGroups {
					localBusinessSchema
					metaDescription
					metaTitle
					openGraphDescription
					openGraphTitle
					productSchema
					image {
						altText
						publicUrl
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
								original {
									width
									height
								}
							}
						}
					}
				}
				homePageFieldGroups {
					bannerSection {
						heading
						subHeading
						description
						cta1 {
							target
							title
							url
						}
						cta2 {
							target
							title
							url
						}
						video {
							altText
							mediaItemUrl
						}
					}
					aboutSection {
						description
						heading
						subHeading
						stats {
							count
							heading
						}
						cta {
							target
							title
							url
						}
						scorecardImage {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					ctaSection {
						heading
						subHeading
						cta2 {
							target
							title
							url
						}
						cta1 {
							target
							title
							url
						}
						rulesDescription
					}
					getInTouch {
						formBtnLabel
						heading
						subHeading
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					googleMapSection {
						heading
						map
						subHeading
						cta {
							target
							title
							url
						}
					}
					twoColumnSection1 {
						description
						heading
						cta {
							target
							title
							url
						}
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					twoColumnSection2 {
						description
						heading
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						pantryMenu {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						cta {
							target
							title
							url
						}
					}
					twoColumnSection3 {
						description
						heading
						cta {
							target
							title
							url
						}
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					welcomeSection {
						description
						heading
						subHeading
						cta {
							target
							title
							url
						}
					}
					reviewsSection {
						heading
						subHeading
						cta {
							target
							title
							url
						}
					}
					gallerySection {
						description
						fieldGroupName
						heading
						cta {
							target
							title
							url
						}
						imageGallery {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			wpMonthlySpecial {
				monthlySpecialFields {
					title
					description
					button {
						link
						text
					}
					logo {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}

			# allInstagramContent(limit: 6, sort: { fields: timestamp, order: DESC }) {
			#   nodes {
			#     localFile {
			#       childImageSharp {
			#         gatsbyImageData(
			#           formats: [AUTO, WEBP]
			#           quality: 100
			#           transformOptions: { cropFocus: CENTER, fit: CONTAIN }
			#           layout: CONSTRAINED
			#           placeholder: BLURRED
			#         )
			#       }
			#     }
			#     caption

			#     permalink
			#     timestamp
			#   }
			# }

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const {
		pageData: { seoFieldGroups, homePageFieldGroups },
		site,
	} = data;

	const {
		bannerSection,
		aboutSection,
		ctaSection,
		getInTouch,
		googleMapSection,
		twoColumnSection1,
		twoColumnSection2,
		twoColumnSection3,
		welcomeSection,
		reviewsSection,
		gallerySection,
	} = homePageFieldGroups;

	const siteUrl = site.siteMetadata.siteUrl;
	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

	const [currentSlides, setCurrentSlides] = useState([0, 0, 0, 0, 0, 0, 0]);

	const updateSlides = (index) => {
		setCurrentSlides((prevSlides) => {
			const updatedSlides = [...prevSlides];
			updatedSlides[index] = updatedSlides[index] === 1 ? 0 : 1;
			return updatedSlides;
		});

		setTimeout(() => {
			const nextIndex = (index + 1) % currentSlides.length;
			updateSlides(nextIndex);
		}, 1500);
	};

	useEffect(() => {
		if (gallerySection?.imageGallery?.length > 0) {
			updateSlides(0);
		}
	}, [gallerySection?.imageGallery]);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	const groupedImages = groupArrayInPairs(gallerySection.imageGallery);

	const gallery1 = groupedImages.slice(0, 3);
	const gallery2 = groupedImages.slice(3, 5);
	const gallery3 = groupedImages.slice(5, 7);

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<LocalBusinessSchema />
			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					{bannerSection &&
						!checkPropertiesForNull(bannerSection, ["heading"]) && (
							<HomeHero
								title={bannerSection?.heading}
								subHeading={bannerSection?.subHeading}
								content={bannerSection?.description}
								button={bannerSection?.cta1}
								button2={bannerSection?.cta2}
								video={bannerSection?.video.mediaItemUrl}
							/>
						)}
					{welcomeSection &&
						!checkPropertiesForNull(welcomeSection, ["heading"]) && (
							<section className="py-4 py-md-5 py-lg-7 bg-primary">
								<Container>
									<Row className="justify-content-center">
										<Col className="text-center" lg={8}>
											<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
												{welcomeSection?.subHeading}
											</p>
											<h2 className="line-heading text-white mb-4 fs-1">
												{welcomeSection?.heading}
											</h2>
											{welcomeSection?.description && (
												<div className="text-white text-center">
													<SafeHtmlParser
														htmlContent={welcomeSection?.description}
													/>
												</div>
											)}
											{welcomeSection.cta && welcomeSection.cta.url && (
												<Button
													style={{ borderRadius: "0px" }}
													variant="outline-white"
													href={welcomeSection.cta.url}
													target={welcomeSection.cta.target ?? "_blank"}
													rel="noreferrer"
													className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-4 px-6 "
												>
													{welcomeSection.cta.title}
												</Button>
											)}
										</Col>
									</Row>
								</Container>
							</section>
						)}
					{aboutSection &&
						!checkPropertiesForNull(aboutSection, ["heading"]) && (
							<BumperBanner
								image={aboutSection.scorecardImage}
								heading={aboutSection?.heading}
								content={aboutSection?.description}
								subHeading={aboutSection?.subHeading}
								items={aboutSection?.stats}
								button={aboutSection?.cta}
							/>
						)}
					{data.wpMonthlySpecial?.monthlySpecialFields?.title.length > 0 && (
						<section className="py-4 py-md-5 py-lg-7 bg-white">
							{" "}
							<Container>
								<Row className="justify-content-center">
									<Col className="text-center" lg={7}>
										<GatsbyImage
											className="mb-3"
											style={{ maxWidth: "100px" }}
											image={
												data.wpMonthlySpecial?.monthlySpecialFields?.logo
													.localFile.childImageSharp.gatsbyImageData
											}
											alt={
												data.wpMonthlySpecial?.monthlySpecialFields.logo.altText
											}
										/>
										<h2 className="  text-primary mb-3 display-5">
											{data.wpMonthlySpecial?.monthlySpecialFields.title}
										</h2>
										<div className="text-primary">
											{parse(
												data.wpMonthlySpecial?.monthlySpecialFields.description
											)}
										</div>
										<Button
											style={{ borderRadius: "0px" }}
											variant="outline-primary"
											target="_blank"
											rel="noreferrer"
											href={
												data.wpMonthlySpecial?.monthlySpecialFields.button.link
											}
											className="py-3 w-100  w-md-auto mb-4 mb-md-0 mt-3 px-6 "
										>
											{data.wpMonthlySpecial?.monthlySpecialFields.button.text}
										</Button>
									</Col>
								</Row>
							</Container>
						</section>
					)}
					{twoColumnSection1 &&
						!checkPropertiesForNull(twoColumnSection1, ["heading"]) && (
							<section className="py-4 py-md-5 py-lg-7 bg-white">
								<Container>
									<Row className="g-5 gx-lg-7 align-items-center">
										<Col lg={6}>
											<GatsbyImage
												className="w-100"
												image={
													twoColumnSection1.image.localFile.childImageSharp
														.gatsbyImageData
												}
												alt={twoColumnSection1.image.altText}
											/>
										</Col>
										<Col lg={6}>
											<h2 className="  text-primary mb-3 display-5">
												{twoColumnSection1?.heading}
											</h2>
											{twoColumnSection1?.description && (
												<div className="text-primary">
													<SafeHtmlParser
														htmlContent={twoColumnSection1?.description}
													/>
												</div>
											)}
											{twoColumnSection1?.cta && (
												<Button
													style={{ borderRadius: "0px" }}
													variant="outline-primary"
													as={Link}
													to={twoColumnSection1?.cta.url}
													className="py-3 w-100  w-md-auto mb-4 mb-md-0 mt-3 px-6 "
													target={twoColumnSection1?.cta.target ?? "_blank"}
												>
													{twoColumnSection1?.cta.title}
												</Button>
											)}
										</Col>
									</Row>
								</Container>
							</section>
						)}
					{twoColumnSection2 &&
						!checkPropertiesForNull(twoColumnSection2, ["heading"]) && (
							<section className="py-4 py-md-5 py-lg-7 bg-primary">
								<Container>
									<Row className="g-5 gx-lg-7 align-items-center">
										<Col lg={6}>
											<h2 className=" text-white mb-3 display-5">
												{twoColumnSection2?.heading}
											</h2>
											{twoColumnSection2?.description && (
												<div className="text-white">
													<SafeHtmlParser
														htmlContent={twoColumnSection2?.description}
													/>
												</div>
											)}
											<MenuModal
												images={twoColumnSection2.pantryMenu}
												buttonText={twoColumnSection2?.cta.title}
											/>
											{/* <Button
                    style={{ borderRadius: "0px" }}
                    variant="outline-white"
                    as={Link}
                    target="_blank"
                    rel="noreferrer"
                    href="https://tanishqs.co.uk"
                    className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-4 px-6 "
                  >
                    Menu
                  </Button> */}
										</Col>
										<Col lg={6}>
											{/* <GatsbyImage
                    className="w-100"
                    image={data.Img1.localFile.childImageSharp.gatsbyImageData}
                    alt={data.Img1.altText}
                  /> */}
											{twoColumnSection2.image &&
												twoColumnSection2.image.length > 0 && (
													<Carousel
														controls={true}
														interval={5500}
														indicators={true}
													>
														{twoColumnSection2.image.map((imageData) => (
															<Carousel.Item key={uuid4()}>
																<GatsbyImage
																	className="mb-3"
																	style={{ height: "626px" }}
																	image={
																		imageData.localFile.childImageSharp
																			.gatsbyImageData
																	}
																	alt={imageData.altText}
																/>
															</Carousel.Item>
														))}
													</Carousel>
												)}
											{/* <Carousel controls={true} interval={3000} indicators={true}>
                    <Carousel.Item>
                      <GatsbyImage
                        className=" mb-3"
                        style={{ height: "626px" }}
                        image={
                          data.Img1.localFile.childImageSharp.gatsbyImageData
                        }
                        alt={data.Img1.altText}
                      />
                    </Carousel.Item>

                    <Carousel.Item>
                      <GatsbyImage
                        className="mb-3"
                        style={{ height: "626px" }}
                        image={
                          data.Img1.localFile.childImageSharp.gatsbyImageData
                        }
                        alt={data.Img1.altText}
                      />
                    </Carousel.Item>


                  </Carousel> */}
										</Col>
									</Row>
								</Container>
							</section>
						)}
					{twoColumnSection3 &&
						!checkPropertiesForNull(twoColumnSection3, ["heading"]) && (
							<section className="py-4 py-md-5 py-lg-7 bg-white">
								<Container>
									<Row className="g-5 gx-lg-7 align-items-center">
										<Col xs={{ span: 12, order: 2 }} lg={6}>
											{twoColumnSection3.image &&
												twoColumnSection3.image.length > 0 && (
													<Carousel
														controls={true}
														interval={5500}
														indicators={true}
													>
														{twoColumnSection3.image.map((imageData) => (
															<Carousel.Item key={uuid4()}>
																<GatsbyImage
																	className="mb-3"
																	style={{ height: "626px" }}
																	image={
																		imageData.localFile.childImageSharp
																			.gatsbyImageData
																	}
																	alt={imageData.altText}
																/>
															</Carousel.Item>
														))}
													</Carousel>
												)}
											{/* <GatsbyImage
                    className="w-100"
                    image={data.Img2.localFile.childImageSharp.gatsbyImageData}
                    alt={data.Img2.altText}
                  /> */}
										</Col>
										<Col xs={{ span: 12, order: 1 }} lg={6}>
											<h2 className="text-primary mb-3 display-5">
												{twoColumnSection3.heading}
											</h2>
											{twoColumnSection3?.description && (
												<div>
													<SafeHtmlParser
														htmlContent={twoColumnSection3?.description}
													/>
												</div>
											)}
											{twoColumnSection3.cta && twoColumnSection3.cta.url && (
												<Button
													style={{ borderRadius: "0px" }}
													variant="outline-primary"
													as={Link}
													target="_blank"
													rel="noreferrer"
													href={twoColumnSection3.cta?.url}
													className="py-3 w-100  w-md-auto mb-lg-4 mb-1 mt-3 px-6 "
												>
													{twoColumnSection3.cta?.title}
												</Button>
											)}
										</Col>
									</Row>
								</Container>
							</section>
						)}
					{reviewsSection &&
						!checkPropertiesForNull(reviewsSection, ["heading"]) && (
							<section className="py-4 py-md-5 py-lg-7 bg-primary">
								<Container>
									<Row className="g-5 gx-lg-7 align-items-center justify-content-center">
										<Col lg={8}>
											{reviewsSection?.subHeading && (
												<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
													{reviewsSection?.subHeading}
												</p>
											)}

											<h2 className="line-heading text-center text-white mb-5 fs-1">
												{reviewsSection?.heading}
											</h2>
										</Col>
									</Row>
									<Reviews reviewsData={data.reviews.reviews} />
									{reviewsSection.cta && reviewsSection.cta?.url && (
										<Col>
											<div className="text-center">
												<Button
													style={{ borderRadius: "0px" }}
													variant="outline-white"
													as={Link}
													target={reviewsSection.cta?.target ?? "_blank"}
													rel="noreferrer"
													href={reviewsSection.cta?.url}
													className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-5 px-6 "
												>
													{reviewsSection.cta?.title}
												</Button>
											</div>
										</Col>
									)}
								</Container>
							</section>
						)}
					{googleMapSection &&
						!checkPropertiesForNull(googleMapSection, ["heading"]) && (
							<section className="py-4 py-md-5 py-lg-7">
								<Container>
									<Row className="g-5 gx-lg-7 align-items-center justify-content-center">
										<Col lg={8}>
											{googleMapSection.subHeading && (
												<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
													{googleMapSection.subHeading}
												</p>
											)}

											<h2 className="line-heading-dark text-center text-dark-green mb-5 fs-1">
												{googleMapSection.heading}
											</h2>
										</Col>
									</Row>

									<Row>
										<Col>
											<iframe
												className="position-relative google-map"
												src={googleMapSection?.map}
												width="100%"
												style="border:0;"
												allowfullscreen=""
												loading="lazy"
												referrerpolicy="no-referrer-when-downgrade"
											></iframe>
											{googleMapSection.cta && googleMapSection.cta.url && (
												<div className="text-center">
													<Button
														style={{ borderRadius: "0px" }}
														variant="outline-primary"
														target={googleMapSection.cta?.target ?? "_blank"}
														rel="noreferrer"
														href={googleMapSection.cta?.url}
														className="py-3 w-100  w-md-auto mb-4 mb-md-0 mt-5 px-6 "
													>
														{googleMapSection.cta?.title}
													</Button>
												</div>
											)}
										</Col>
									</Row>
								</Container>
							</section>
						)}
					{/* <section className="py-5 bg-primary py-lg-7">
            <Container>
              <Row className="g-5 gx-lg-7 align-items-center justify-content-center">
                <Col lg={8}>
                  <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                    follow us @toot_hill
                  </p>
                  <h2 className="line-heading text-center text-white mb-5 fs-1">
                    Our latest Instagram posts
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row className="g-5">
                    {data.allInstagramContent.nodes.map((post) => (
                      <Col md={6} lg={4}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={post.permalink}
                          className="text-decoration-none"
                        >
                          <div className="rect-img-container">
                            <GatsbyImage
                              className="w-100 rect-img"
                              image={
                                post.localFile.childImageSharp.gatsbyImageData
                              }
                              alt={post.caption}
                            />
                          </div>
                        </a>
                      </Col>
                    ))}
                  </Row>
                  <div className="text-center mt-5 d-flex justify-content-center align-items-center">
                    <a href="#">
                      <img
                        src={InstaLogo}
                        alt="Instagram Logo"
                        className=" me-4 d-inline-block "
                        style={{
                          maxWidth: "100px",
                        }}
                      />
                    </a>
                    <a href="#">
                      <img
                        src={FacebookLogo}
                        alt="Facebook Logo"
                        className=" me-4  d-inline-block "
                        style={{
                          maxWidth: "100px",
                        }}
                      />
                    </a>
                    <a href="#">
                      <img
                        src={XLogo}
                        alt="X Logo"
                        className=" me-4 d-inline-block "
                        style={{
                          maxWidth: "100px",
                        }}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section> */}

					{gallerySection &&
						!checkPropertiesForNull(gallerySection, ["heading"]) && (
							<section className="py-4 py-md-5 py-lg-7">
								<Container>
									<Row>
										<Col>
											<h2 className="text-primary mb-4 fs-1">
												{gallerySection?.heading}
											</h2>
											{gallerySection.description && (
												<div className="mb-4">
													<SafeHtmlParser
														htmlContent={gallerySection?.description}
													/>
												</div>
											)}
										</Col>
									</Row>

									{gallerySection?.imageGallery &&
										gallerySection.imageGallery.length > 0 && (
											<>
												<Row className="d-none d-md-flex">
													<Col md={6} lg={4} className="g-3">
														{gallery1.map((pair, i) => (
															<ImageCarousel
																images={pair}
																height="310px"
																activeslide={currentSlides[i]}
																key={i}
															/>
														))}
													</Col>
													<Col md={6} lg={4} className="g-3">
														{gallery2.map((pair, i) => (
															<ImageCarousel
																images={pair}
																height={i === 0 ? "310px" : "636px"}
																activeslide={currentSlides[i + 3]}
																key={i}
															/>
														))}
													</Col>
													<Col lg={4} className="g-3 d-none d-lg-block">
														{gallery3.map((pair, i) => (
															<ImageCarousel
																images={pair}
																height={i === 0 ? "636px" : "310px"}
																activeslide={currentSlides[i + 5]}
																key={i}
															/>
														))}
													</Col>
												</Row>
												<div className="d-md-none d-grid gallery-wrapper gap-3">
													{gallerySection.imageGallery
														.slice(0, 6)
														.map((item, i) => (
															<div className="gallery-item" key={i}>
																<GatsbyImage
																	className="w-100 mb-3 d-lg-none"
																	style={{ height: "100%" }}
																	image={
																		item.localFile.childImageSharp
																			.gatsbyImageData
																	}
																	alt={item.altText}
																/>
															</div>
														))}
												</div>
											</>
										)}
									{gallerySection.cta && gallerySection.cta.url && (
										<Row>
											<Col>
												<div className="text-center">
													<Button
														style={{ borderRadius: "0px" }}
														variant="outline-primary"
														as={Link}
														to={gallerySection.cta.url}
														className="py-3 w-100 w-md-auto mb-4 mb-md-0 mt-4 px-6"
														target={gallerySection.cta?.target ?? "_self"}
													>
														{gallerySection.cta?.title}
													</Button>
												</div>
											</Col>
										</Row>
									)}
								</Container>
							</section>
						)}

					{ctaSection && !checkPropertiesForNull(ctaSection, ["heading"]) && (
						<section className="py-4 py-md-5 py-lg-7 bg-primary">
							<Container>
								<Row className="justify-content-center">
									<Col className=" text-white text-center" lg={8}>
										{ctaSection.subHeading && (
											<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
												{ctaSection.subHeading}
											</p>
										)}

										<h2 className="line-heading text-white mb-4 fs-1">
											{ctaSection?.heading}
										</h2>
										{/* <p className="text-white">
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Tenetur, similique harum ut nam atque error incidunt
										aspernatur nobis, sint, molestiae nihil voluptatem omnis
										aperiam molestias voluptates vel facere aut repellendus!
									</p> */}

										{/* <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                    Our rules
                  </p>
                  <h2 className="line-heading text-white mb-4 fs-1">
                    Course rules
                  </h2>
                  <p className="text-white">Local Rules & Course Map</p>
                  <h3 className="text-white">Out of Bounds (Rule 18.2)</h3>
                  <ol type="a">
                    <li className="text-white">
                      Beyond any fence, hedge or stake bounding the course.
                    </li>
                    <li className="text-white">
                      In the car park, the Clubhouse or its paved surround.
                    </li>
                    <li className="text-white">
                      In the ditch to the left of the 7th, 10th and 16th holes
                      and to the rear of the 7th green.
                    </li>
                    <li className="text-white">
                      The practice ground as defined by the cut area.
                    </li>
                  </ol>
                  <h3 className="text-white">Penalty Areas (Rule 17)</h3>
                  <p className="text-white">
                    Ordinary penalty areas are denoted by yellow stakes or lines
                    (Rules 17.1d(1) and (2)).
                  </p>
                  <p className="text-white">
                    Lateral penalty areas are denoted by red stakes or lines
                    (Rule 17.1d(3)).
                  </p>
                  <p className="text-white">
                    Unless defined by lines or stakes, the margin of the penalty
                    area is the edge of the water.
                  </p>
                  <h3 className="text-white">
                    Integral Parts of the Course (Rule 8.1)
                  </h3>
                  <p className="text-white">
                    The following are integral parts of the course. The ball
                    must be played as it lies or declared unplayable (Rule 19).
                  </p>
                  <ol type="a">
                    <li className="text-white">All bridges on the course.</li>
                    <li className="text-white">
                      The sleeper and log headwalls at the lake on holes 12 and
                      15.
                    </li>
                    <li className="text-white">
                      The log retaining wall at the stream on the 4th hole.
                    </li>
                  </ol>
                  <h3 className="text-white">
                    Movable Obstructions and Loose Impediments (Rule 15)
                  </h3>
                  <p className="text-white">
                    If a player's ball is in a bunker, he/she may move both
                    movable obstructions "artificial objects" and loose
                    impediments "natural objects" (stones, leaves, twigs, etc).
                    If the ball moves when moving a moveable obstruction, the
                    player is not penalized. However, if the ball moves when
                    moving a loose impediment, your ball must be replaced on its
                    original spot and you get a one penalty stroke. Rule 15.1b
                    applies.
                  </p>
                  <h3 className="text-white">Staked Trees</h3>
                  <p className="text-white">
                    Young trees identified by stakes are no play zones:
                  </p>
                  <p className="text-white">
                    If a player's ball lies anywhere on the course other than in
                    a penalty area and it lies on or touches such a tree or such
                    a tree interferes with the player's stance or area of
                    intended swing, the ball MUST be lifted, without penalty and
                    dropped within one club-length not nearer the hole. Rule
                    16.1f applies.
                  </p>
                  <h3 className="text-white">Overhead Wires</h3>
                  <p className="text-white">
                    A ball striking the wires, or supporting pole, MUST be
                    replayed, without penalty, using Rule 14.6. The original
                    stroke does not count. Posts and stays supporting the lines
                    are immovable obstructions and qualify only for relief under
                    Rule 16.1.
                  </p>
                  <h3 className="text-white">
                    Immovable Obstructions (Rule 16.1)
                  </h3>
                  <p className="text-white">
                    These are artificial objects which are not movable i.e.
                    signage posts, fixed yardage posts, sprinkler heads, etc.
                  </p>
                  <h3 className="text-white">Roads and Paths (Rule 16)</h3>
                  <p className="text-white">
                    All artificially surfaced roads and paths are immovable
                    obstructions (Rule 16.1a applies). The ball may be played as
                    it lies, or relief may be taken without penalty by lifting
                    and dropping the ball within one club-length of and not
                    nearer the hole than the nearest point of relief.
                  </p>
                  <h3 className="text-white">
                    Fixed Sprinkler Heads (Rule 16)
                  </h3>
                  <p className="text-white">
                    All fixed sprinkler heads are immovable obstructions and
                    relief without penalty is available under Rule 16.1b. In
                    addition, if a ball lies in the general area and an
                    immovable obstruction on or within two club-lengths of the
                    putting green and within two club-lengths of the ball
                    intervenes on the line of play between the ball and the
                    hole,{" "}
                  </p> */}

										<RulesModal
											content={ctaSection?.rulesDescription}
											buttonText={ctaSection?.cta1.title}
										/>
										{/*
                  <Button
                    style={{ borderRadius: "0px" }}
                    variant="white"
                    as={Link}
                    to="/"
                    className="py-3 w-100 text-primary w-md-auto mb-4 mb-md-0 mt-4 px-6 me-md-4 "
                  >
                    Book a tee time
                  </Button> */}

										{ctaSection.cta2 && ctaSection.cta2.url && (
											<Button
												style={{ borderRadius: "0px" }}
												variant="outline-white"
												as={Link}
												to={ctaSection.cta2.url}
												target={ctaSection.cta2.target ?? "_self"}
												className="py-3 w-100 white-link w-md-auto mb-4 px-6 px-md-5 px-lg-5 mt-3 my-lg-0 my-md-0"
											>
												{ctaSection.cta2?.title}
											</Button>
										)}
									</Col>
								</Row>
							</Container>
						</section>
					)}
					{getInTouch && !checkPropertiesForNull(getInTouch, ["heading"]) && (
						<section className="py-4 py-md-5 py-lg-7">
							<Container>
								<Row className="g-5 gx-lg-7 align-items-center justify-content-center">
									<Col lg={8}>
										{getInTouch.subHeading && (
											<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
												{getInTouch.subHeading}
											</p>
										)}

										<h2 className="line-heading-dark text-center  text-dark-green mb-5 fs-1">
											{getInTouch?.heading}
										</h2>
									</Col>
								</Row>

								<Row className="g-5 justify-content-between align-items-center h-100 gx-lg-7  gx-xl-0">
									<Col styl={{ minHeight: "100%" }} lg={6} xl={5}>
										<div
											className="h-100"
											style={{ borderRadius: "8px", overflow: "hidden" }}
										>
											<GatsbyImage
												style={{ borderRadius: "8px" }}
												className="w-100 h-100"
												image={
													getInTouch.image?.localFile.childImageSharp
														.gatsbyImageData
												}
												alt={getInTouch.image.altText}
											/>
										</div>
									</Col>
									<Col lg={6} xl={5}>
										<ContactForm buttonText={getInTouch.formBtnLabel} />
									</Col>
								</Row>
							</Container>
						</section>
					)}
				</Layout>
			</div>
		</>
	);
};

export default IndexPage;
