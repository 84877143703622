import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				reviews {
					reviewCount
				}
				jeffVanImg: wpMediaItem(title: { eq: "Hero-Home" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
		`
	);

	const localBusinessSchema = {
		"@context": "https://schema.org",
		"@type": "LocalBusiness",
		"@id": "https://www.toothillgolfclub.co.uk",
		name: "Toot Hill Golf Course",
		legalName: "Toot Hill Golf Course",
		description:
			"Located just off the M11 and M25 this undulating parkland course is undoubtedly one of the finest in Essex.",
		url: "https://www.toothillgolfclub.co.uk",
		priceRange: "$$",
		areaServed: [
			{
				"@type": "City",
				name: "Essex",
				sameAs: "https://en.wikipedia.org/wiki/Essex",
			},
		],
		email: "office@toothillgolfclub.co.uk",
		currenciesAccepted: "GBP",
		numberOfEmployees: "20",
		location: {
			"@type": "City",
			name: "Essex",
			sameAs: "https://en.wikipedia.org/wiki/Essex",
		},
		foundingLocation: {
			"@type": "City",
			name: "Essex",
			sameAs: "https://en.wikipedia.org/wiki/Essex",
		},
		slogan: "No Ordinary Course",
		telephone: "+441277365523",
		address: {
			"@type": "PostalAddress",
			addressLocality: "Essex",
			addressRegion: "East of England",
			postalCode: "CM5 9PU",
			streetAddress: "Toot Hill Golf Club, School Road, Toot Hill, Ongar",
			addressCountry: "United Kingdom",
		},
		geo: {
			"@type": "GeoCoordinates",
			latitude: "51.695872",
			longitude: "0.193742",
		},
		hasMap: "https://maps.app.goo.gl/L97kNsc6yTz4bPYE9",
		openingHours: "Mo,Tu,We,Th,Fr,Sa,Su 07:30-19:00",
		openingHoursSpecification: [
			{
				"@type": "OpeningHoursSpecification",
				closes: "19:00",
				dayOfWeek: "http://schema.org/Sunday",
				opens: "07:30",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "19:00",
				dayOfWeek: "http://schema.org/Monday",
				opens: "07:30",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "19:00",
				dayOfWeek: "http://schema.org/Tuesday",
				opens: "07:30",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "19:00",
				dayOfWeek: "http://schema.org/Wednesday",
				opens: "07:30",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "19:00",
				dayOfWeek: "http://schema.org/Thursday",
				opens: "07:30",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "19:00",
				dayOfWeek: "http://schema.org/Friday",
				opens: "07:30",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "19:00",
				dayOfWeek: "http://schema.org/Saturday",
				opens: "07:30",
			},
		],
		// founder: {
		// 	"@type": "Person",
		// 	name: "John Smith",
		// 	id: "https://www.example.co.uk/who-we-are#johnSmith",
		// 	url: "https://www.example.co.uk/who-we-are",
		// 	description: "",
		// },
		aggregateRating: {
			"@type": "AggregateRating",
			bestRating: "5",
			worstRating: "1",
			ratingValue: "4.9",
			reviewCount: `${data.reviews.reviewCount}`,
			url: "https://www.toothillgolfclub.co.uk/contact-us",
		},
		contactPoint: {
			"@type": "ContactPoint",
			"@id": "https://www.toothillgolfclub.co.uk/contact-us",
			url: "https://www.toothillgolfclub.co.uk/contact-us",
			// "image": `${data.schemaLogo.publicURL}`,
			name: "Toot Hill Golf Course",
			description:
				"Get in touch with us if you have any questions about Toot Hill Golf Course or our facilities.",
			telephone: "+441277365523",

			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "Essex",
					sameAs: "https://en.wikipedia.org/wiki/Essex",
				},
			],
		},
		logo: `https://toot-hill-golf.rjmdigital.net/wp-content/uploads/2023/12/icon.png`,
		image: `https://toot-hill-golf.rjmdigital.net/wp-content/uploads/2023/09/Hero-Home-scaled.jpg`,
		sameAs: [
			"https://www.instagram.com/toothill_golf_club/",
			"https://www.facebook.com/TootHillGolfClub/?locale=en_GB",
		],
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
